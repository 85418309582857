
@tailwind base;
@tailwind components;
@tailwind utilities;

.animate__zoomIn:hover{
    animation: myAnim 0.5s ease-in-out 0s 1 normal forwards;
  }
  @keyframes myAnim {
      0% {
          /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
          transform: scale(1);
      }
  
      100% {
          /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); */
          transform: scale(1.03);
      }
  }

  .animate__zoomIn2:hover{
    animation: myAnim2 0.5s ease-in-out 0s 1 normal forwards;
  }
  @keyframes myAnim2 {
      0% {
          /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
          transform: scale(1);
      }
  
      100% {
          /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); */
          transform: scale(1.3);
      }
  }


  